import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import { StaticImage } from 'gatsby-plugin-image'
import s1 from '../images/pragicts-software-development-process.png'
const SupportPage = () => {
    return (
        <Layout pageTitle="PragICTS | Cyber Security Operations (CyberSecOps) | Software Development Process" og_desc="SEP by PragICTS">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>SOFTWARE ENGINEERING<br/>
<span> PROCESS</span></h2>
                                <p></p>
                                <div className="horizonral-subtitle"><span>PROCESS</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>process</div>
                        <div className="container main-about">
                            <h2>Purpose</h2>
                            <p>This provides the high-level details of the standard software engineering process (<div class="tooltip-process">
    SEP
    <span class="tooltiptext-process">Software Engineering Process</span>
</div>) practiced by PragICTS.<br/>As each customer engagement is unique, the standard <div class="tooltip-process">
    SEP
    <span class="tooltiptext-process">Software Engineering Process</span>
</div> process will be tailored to cater to the specific requirements of the client/project.</p>

                        

<h2>Software Engineering @ PragICTS</h2>
<p>
Some of our guiding thoughts / context pertaining to engineering which has shaped the
way we approach and deal with it.
</p>

<div style={{ borderBottom:'none !important'}}>
<p style={{ textAlign: 'center',fontSize: '15px',color:'black'}}><span style={{fontWeight: 'bolder' }}>'Engineering cannot be an afterthought.<br/>
It is a disciplined process & effort from the inception'</span><br/>PragICTS</p>

<p style={{ textAlign: 'center',fontSize: '15px',color:'black'}}><span style={{fontWeight: 'bolder' }}>''Life is really simple<br/>
We insist on making it complicated.'</span><br/>Confucius</p>

<p style={{ textAlign: 'center',fontSize: '15px',color:'black'}}><span style={{fontWeight: 'bolder' }}>'Every solution to every problem is simple.<br/>
It's the distance between the two where the mystery lies.'</span><br/>Derek Landy</p>

<p style={{ textAlign: 'center',fontSize: '15px',color:'black'}}><span style={{fontWeight: 'bolder' }}>'There is no greatness<br/>
where there is not simplicity, goodness, and truth.'</span><br/>Leo Tolstoy</p>
</div>

<p>
We believe that a pragmatic and simplistic approach to software engineering is the baseline for value creation.
</p>

<h2>Process Characteristics</h2>
<p>
The <div class="tooltip-process">
    SEP
    <span class="tooltiptext-process">Software Engineering Process</span>
</div> is articulated with the following characteristics to optimize a constant and
consistant delivery every time.
</p>     
<div>
<p>
    <h6 style={{fontWeight: 'bolder',fontSize: '15px',color:'black' }}>1. Requirements and design focus</h6>
    <span>Requirements and design are given a far greater focus and effort to ensure that the
requirements are understood and scoped to meet customer expectations and
effective project planning and execution.<br/>
Eliminates surprises at the completion stages.</span>
</p>

<p>
<h6 style={{fontWeight: 'bolder',fontSize: '15px',color:'black' }}>2. Customer engaged process from the very inception</h6>
    <span>Customer is actively involved in the front stages relating to requirements articulation,
design and prototyping.<br/>
Customer is also involved at key review points to eliminate disagreements and
changes at the middle and later stages.</span>
</p>

<p>
<h6 style={{fontWeight: 'bolder',fontSize: '15px',color:'black' }}>3. Customer review and sign-off at all key milestones</h6>
    <span>All key review milestones are reviewed and signed off with the customer to ensure
discipline and clarity.</span>
</p>

<p>
<h6 style={{fontWeight: 'bolder',fontSize: '15px',color:'black' }}>4. Seeing is believing</h6>
    <span>Prototypes and application <div class="tooltip-process">
    UI
    <span class="tooltiptext-process">User Interface</span>
</div> are presented to ensure application <div class="tooltip-process">
    UI
    <span class="tooltiptext-process">User Interface</span>
</div> and
functionality clarity at early stages of development milestones to ensure that
customer knows what to expect of the delivery</span>
</p>

<p>
<h6 style={{fontWeight: 'bolder',fontSize: '15px',color:'black' }}>5. Hybrid model<br/>
Both Agile and Waterfall techniques are brought together for effective
engineering and delivery</h6>
    <span>Selected key modules will be delivered on an iterative development process with
customer reviews to ensure that the correct functionality is delivered with great
usability.<br/>
The Waterfall approach is initiated at the completion of the design stages for faster
execution of software coding activities.</span>
</p>

<p>
<h6 style={{fontWeight: 'bolder',fontSize: '15px',color:'black' }}>6. Minimum documentation</h6>
    <span>Minimum documentation is maintained to minimize unnecessary time and effort
wastage.</span>
</p>
</div> 


<h2>Engineering Process</h2>

<p>The standard <div class="tooltip-process">
    SEP
    <span class="tooltiptext-process">Software Engineering Process</span>
</div> is defined and refined for constant delivery, quality and customer delight
with minimum documentation to expedite delivery as follows</p>

<div class="fl-wrap vertical-gallery">
                                               
                                               <div class="box-item">
                                                   <img style={{height: '80%', width: '70%' }} src={s1} alt=""/>
                                                   <a href={s1} class="fet_pr-carousel-box-media-zoom   image-popup"><i class="fal fa-search"></i></a>
                                               </div>
                                                                       
                                           </div>

<h2>Process Stages</h2>

<div>
<p>
    <h6 style={{fontWeight: 'bolder',fontSize: '15px',color:'black' }}>1. Requirements</h6>
    <span>Requirements are captured and recorded in granular form and reviwed and
signed off with the client. There articulated requirements are followed right
through out the project for effective communication, engineering and
delivery.</span>
<h6 style={{fontWeight: 'bold' }}>Trigger</h6>
<p><ul><li><i class="fal fa-arrow-right"></i>Confirmation of the <div class="tooltip-process">
    PSE
    <span class="tooltiptext-process">Proposal For Software Engineering</span>
</div></li>


</ul>
</p>

<h6 style={{fontWeight: 'bold' }}>Inputs</h6>
<p><ul><li><i class="fal fa-arrow-right"></i><div class="tooltip-process">
    PSE
    <span class="tooltiptext-process">Proposal For Software Engineering</span>
</div></li><li><i class="fal fa-arrow-right"></i>Email correspondence</li>


</ul>
</p>

<h6 style={{fontWeight: 'bold' }}>Methods</h6>
<p><ul><li><i class="fal fa-arrow-right"></i>Direct discussion with the customer to detail the requirements beyond
the <div class="tooltip-process">
    PSE
    <span class="tooltiptext-process">Proposal For Software Engineering</span>
</div>.</li>
<p>Note : All requirements capture and discussions sessions are <span style={{fontWeight: 'bolder' }}>recorded using
professional recording instruments</span> for reference, verifications, elimation of
omissions and subsequent requirements articulation with client approval.
These are stored as part of the project folder.</p>
<li><i class="fal fa-arrow-right"></i>Email correspondence</li>
</ul>
</p>




<h6 style={{fontWeight: 'bold' }}>Outputs</h6>
<p><ul><li><i class="fal fa-arrow-right"></i><div class="tooltip-process">
    RS
    <span class="tooltiptext-process">Requirements Specification</span>
</div>. Detailed requirements are numbered and listed for customer
review and sign off</li>


</ul>
</p>
</p>









<p>
    <h6 style={{fontWeight: 'bolder',fontSize: '15px',color:'black' }}>2. Design</h6>
    
<h6 style={{fontWeight: 'bold' }}>Trigger</h6>
<p><ul><li><i class="fal fa-arrow-right"></i>Confirmation of the <span style={{fontWeight: 'bolder' }}><div class="tooltip-process">
    RS
    <span class="tooltiptext-process">Requirements Specification</span>
</div></span></li>


</ul>
</p>

<h6 style={{fontWeight: 'bold' }}>Inputs</h6>
<p><ul><li style={{fontWeight: 'bolder' }}><i class="fal fa-arrow-right"></i><div class="tooltip-process">
    PSE
    <span class="tooltiptext-process">Proposal For Software Engineering</span>
</div> and <div class="tooltip-process">
    RS
    <span class="tooltiptext-process">Requirements Specification</span>
</div></li><li><i class="fal fa-arrow-right"></i>Email correspondence</li>


</ul>
</p>

<h6 style={{fontWeight: 'bold' }}>Methods</h6>
<p><ul><li><i class="fal fa-arrow-right"></i>Application architechture</li>
<li><i class="fal fa-arrow-right"></i>Database design</li>
<li><i class="fal fa-arrow-right"></i>Component framework</li>
<li><i class="fal fa-arrow-right"></i><div class="tooltip-process">
    UI
    <span class="tooltiptext-process">User Interface</span>
</div> framework</li>
<li><i class="fal fa-arrow-right"></i>Prototyping of key components</li>
</ul>
</p>


<h6 style={{fontWeight: 'bold' }}>Outputs</h6>
<p><ul><li><i class="fal fa-arrow-right"></i>Customer confirmation of the above methods</li>


</ul>
</p>
</p>









<p>
    <h6 style={{fontWeight: 'bolder',fontSize: '15px',color:'black' }}>3. Prototyping</h6>
    
<h6 style={{fontWeight: 'bold' }}>Trigger</h6>
<p><ul><li><i class="fal fa-arrow-right"></i>Confirmation of the <span style={{fontWeight: 'bolder' }}><div class="tooltip-process">
    RS
    <span class="tooltiptext-process">Requirements Specification</span>
</div></span></li>
<li><i class="fal fa-arrow-right"></i>In the design stage</li>

</ul>
</p>

<h6 style={{fontWeight: 'bold' }}>Inputs</h6>
<p><ul><li style={{fontWeight: 'bolder' }}><i class="fal fa-arrow-right"></i><div class="tooltip-process">
    PSE
    <span class="tooltiptext-process">Proposal For Software Engineering</span>
</div> and <div class="tooltip-process">
    RS
    <span class="tooltiptext-process">Requirements Specification</span>
</div></li>


</ul>
</p>

<h6 style={{fontWeight: 'bold' }}>Methods</h6>
<p><ul><li><i class="fal fa-arrow-right"></i>Develop the required functions for the prototype scope. An iterative
process with customer reviews and amendments.</li>

</ul>
</p>


<h6 style={{fontWeight: 'bold' }}>Outputs</h6>
<p><ul><li><i class="fal fa-arrow-right"></i>Customer confirmation of the prototype</li>


</ul>
</p>
</p>








<p>
    <h6 style={{fontWeight: 'bolder',fontSize: '15px',color:'black' }}>4. Hybrid Development</h6>
    <span>1. Prototyping / Iterative of critical and foundation components</span><br/>
    <span>2. Waterfall development</span>
    
<h6 style={{fontWeight: 'bold' }}>Trigger</h6>
<p><ul><li><i class="fal fa-arrow-right"></i>Confirmation of the <span style={{fontWeight: 'bolder' }}><div class="tooltip-process">
    RS
    <span class="tooltiptext-process">Requirements Specification</span>
</div></span></li>
<li><i class="fal fa-arrow-right"></i>In the design stage</li>

</ul>
</p>

<h6 style={{fontWeight: 'bold' }}>Inputs</h6>
<p><ul><li style={{fontWeight: 'bolder' }}><i class="fal fa-arrow-right"></i><div class="tooltip-process">
    PSE
    <span class="tooltiptext-process">Proposal For Software Engineering</span>
</div> and <div class="tooltip-process">
    RS
    <span class="tooltiptext-process">Requirements Specification</span>
</div></li>


</ul>
</p>

<h6 style={{fontWeight: 'bold' }}>Methods</h6>
<p><ul><li><i class="fal fa-arrow-right"></i>Prototyping of core foundations as required</li>
<li><i class="fal fa-arrow-right"></i>Waterfall development for the rest of the modules</li>
</ul>
</p>


<h6 style={{fontWeight: 'bold' }}>Outputs</h6>
<p><ul><li><i class="fal fa-arrow-right"></i>Completion of the prototype and the completed modules</li>


</ul>
</p>
</p>





<p>
    <h6 style={{fontWeight: 'bolder',fontSize: '15px',color:'black' }}>5. Testing</h6>
    <p>Testing of the application for conformity and consistancy. Compilation of the
STS is required for the commencement of testing</p>
    
<h6 style={{fontWeight: 'bold' }}>Trigger</h6>
<p><ul><li><i class="fal fa-arrow-right"></i>Completion of modules for testing</li>


</ul>
</p>

<h6 style={{fontWeight: 'bold' }}>Inputs</h6>
<p><ul><li><i class="fal fa-arrow-right"></i>STS</li>
<li><i class="fal fa-arrow-right"></i><div class="tooltip-process">
    STG
    <span class="tooltiptext-process">Software Testing Guidelines</span>
</div></li>
<li><i class="fal fa-arrow-right"></i>and <div class="tooltip-process">
    TC
    <span class="tooltiptext-process">Test Checklist</span>
</div></li>

</ul>
</p>

<h6 style={{fontWeight: 'bold' }}>Methods</h6>
<p><ul><li><i class="fal fa-arrow-right"></i>Test automation tools</li>
<li><i class="fal fa-arrow-right"></i>Execution of STS, <div class="tooltip-process">
    STG
    <span class="tooltiptext-process">Software Testing Guidelines</span>
</div>, <div class="tooltip-process">
    TC
    <span class="tooltiptext-process">Test Checklist</span>
</div></li>
</ul>
</p>


<h6 style={{fontWeight: 'bold' }}>Outputs</h6>
<p><ul><li><i class="fal fa-arrow-right"></i>Compilation of <div class="tooltip-process">
    STR
    <span class="tooltiptext-process">Software Test Report</span>
</div> for completion of testing</li>


</ul>
</p>
</p>




<p>
    <h6 style={{fontWeight: 'bolder',fontSize: '15px',color:'black' }}>6. User Review for Acceptance</h6>
    <p>Customer to review the software as per the given time shedules. Once it is
completed the customer is required to sign-off the <div class="tooltip-process">
    SAF
    <span class="tooltiptext-process">Software Acceptance Form</span>
</div></p>
  
</p>

<p>
    <h6 style={{fontWeight: 'bolder',fontSize: '15px',color:'black' }}>7. Live Implmentation</h6>
    <p>On formal confirmation and sign-off of the <div class="tooltip-process">
    SAF
    <span class="tooltiptext-process">Software Acceptance Form</span>
</div>, the live implementation will
happen. On live implementation customer is required to sign off the <div class="tooltip-process">
    SHF
    <span class="tooltiptext-process">Software Handover Form</span>
</div></p>
  
</p>

<p>
    <h6 style={{fontWeight: 'bolder',fontSize: '15px',color:'black'}}>8. Support</h6>
    <p>Application support will be provided as per the signed-off   <div class="tooltip-process">
    CSA
    <span class="tooltiptext-process">Customer Support Agreement</span>
</div></p>
  
</p>

</div>



<h2>Glossary</h2>

<table class="table" style={{border:'1.5px solid gray'}}>
    <tbody>
      
        <tr>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>Customer Support Agreement (CSA)</td>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>CSA provides the scope, terms and agreements, responsibilities, and SLAs for the support to be provided by PragICTS.</td>
        </tr>
        <tr>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>Proposal For Software Engineering (PSE)</td>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>PSE is provided where the requirements scope of the application is articulated in detail with clarity for providing a fixed price PSE for execution.</td>
        </tr>
        <tr>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>Proposition Brief For Software Engineering (PBSE)</td>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>PBSE is provided where the scope of the requirement is specified at a high level and not at a detailed level and requires detailing and clarity. PBSE will factor in the pricing and effort for a detailed requirements study and for the revision of the final pricing at post-detailed requirements finalization.</td>
        </tr>
        <tr>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>Requirements Specification (RS)</td>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>RS numbers and articulates requirements at an atomic and granular level. This will form the basis definitive basis of the requirements. Any requirement not captured here is not taken as part of the requirements. Therefore it is one of the key activities of the development process as this will serve as the primary input to proposition compilation, estimation, features, design, and functionalities of the application. User testing and customer acceptance will be solely based on the RS. RS will cover application functionality, technology stack, architecture, UI/UX, etc.</td>
        </tr>
        <tr>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>Software Acceptance Form (STF)</td>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>STF is executed on the completion of the acceptance of the software by the client. This concludes the development commitment of PragICTS and brings to the closure of the undertaking.</td>
        </tr>
        <tr>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>Software Engineering Process (SEP)</td>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>SEP is the process executed by PragICTS for software development. The generic process is articulated here. As each requirement is unique, the process is futher tailored to meet the specific requirements of the client and project.</td>
        </tr>
        <tr>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>Software Handover Form (SHF)</td>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>Once the STF is signed-off the SHF is compiled with the software handover as a reference to detail the complete software and environment details as a point of reference to the software.</td>
        </tr>
        <tr>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>Software Test Specification (STA)</td>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>STA is derived and compiled based on the RS for the testing scope of the software for compliance and conformance. This ensures for a through due deligence process.</td>
        </tr>
        <tr>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>Software Testing Guidelines (STG)</td>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>STG is a guideline of best practices for effective and through testing.</td>
        </tr>
        <tr>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>Software Test Report (STR)</td>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>STR compiled as an output of the STA to articulate the outcomes of the tests executed.</td>
        </tr>
        <tr>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>Test Checklist (TC)</td>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>TC is a standard checklist for the testing of common test parameters based on application types. These help to standardize the testing processes</td>
        </tr>
        <tr>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>User Interface / User Experience (UI/UX)</td>
            <td style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',padding:'5px',textAlign:'left'}}>In digital design, user interface (UI) refers to the interactivity, look, and feel of a product screen or web page, while user experience (UX) covers a user's overall experience with the product or website.</td>
        </tr>
    </tbody>
</table>











                        </div>
                     
                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>
                        
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SupportPage
